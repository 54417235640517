.modal-content {
  max-height: 85vh;
  overflow-y: scroll;
  padding-bottom: 62px;
}

/* Mobile safari hack to account for footer */
@media screen and (max-width: 767px) {
  _::-webkit-full-page-media,
  .modal-content {
    max-height: 78vh;
  }
}

.modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 1px 20px 0px #64646f33;

  @apply bg-gray-200 px-4 py-3 sm:px-6 flex justify-end;
}
