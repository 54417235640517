.splash-page {
}

.hero {
  height: calc(100vh - 70px); /* header height */
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../images/splash-hero-bg.svg);

  @apply px-4 sm:pt-12 sm:px-6 md:pt-16 pt-10 lg:pt-20 lg:px-8 xl:pt-28;
}

/* Mobile safari hack to account for footer */
@media screen and (max-width: 767px) {
  _::-webkit-full-page-media,
  .hero {
    height: calc(100vh - 100px); /* header height + mobile footer */
  }
}
